<template>
  <el-dialog
    :title="$t('warehouse.createWarehouse')"
    v-model="isShow"
    width="700px"
    :before-close="handleClose"
  >
    <el-form
      ref="warehouseForm"
      :inline="true"
      :model="warehouseInfo"
      size="mini"
      :rules="warehouseRules"
    >
      <div class="row">
        <el-form-item
          :label="$t('warehouse.warehouseType')"
          prop="warehouseType"
          label-width="120px"
        >
          <el-select
            filterable
            v-model="warehouseInfo.warehouseType"
            :placeholder="$t('warehouse.warehouseType')"
            clearable
            size="mini"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in warehouseTypeList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('warehouse.warehouseCode')"
          prop="warehouseCode"
          label-width="120px"
        >
          <el-input
            v-model.trim="warehouseInfo.warehouseCode"
            :placeholder="
              $t('system.pleaseInput') + $t('warehouse.warehouseCode')
            "
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('warehouse.companyName')"
          prop="companyName"
          label-width="120px"
        >
          <el-input
            v-model.trim="warehouseInfo.companyName"
            :placeholder="
              $t('system.pleaseInput') + $t('warehouse.companyName')
            "
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('warehouse.consignee')"
          prop="consignee"
          label-width="120px"
        >
          <el-input
            v-model.trim="warehouseInfo.consignee"
            :placeholder="$t('system.pleaseInput') + $t('warehouse.consignee')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('warehouse.consigneeAddress1')"
          prop="consigneeAddress1"
          label-width="120px"
        >
          <el-input
            v-model.trim="warehouseInfo.consigneeAddress1"
            :placeholder="
              $t('system.pleaseInput') + $t('warehouse.consigneeAddress1')
            "
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('warehouse.consigneeAddress2')"
          prop="consigneeAddress2"
          label-width="120px"
        >
          <el-input
            v-model.trim="warehouseInfo.consigneeAddress2"
            :placeholder="
              $t('system.pleaseInput') + $t('warehouse.consigneeAddress2')
            "
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('warehouse.consigneeAddress3')"
          prop="consigneeAddress3"
          label-width="120px"
        >
          <el-input
            v-model.trim="warehouseInfo.consigneeAddress3"
            :placeholder="
              $t('system.pleaseInput') + $t('warehouse.consigneeAddress3')
            "
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('warehouse.houseNumber')"
          prop="housenumber"
          label-width="120px"
        >
          <el-input
            v-model.trim="warehouseInfo.housenumber"
            :placeholder="
              $t('system.pleaseInput') + $t('warehouse.houseNumber')
            "
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('warehouse.country')"
          prop="country"
          label-width="120px"
        >
          <el-input
            v-model.trim="warehouseInfo.country"
            :placeholder="$t('system.pleaseInput') + $t('warehouse.country')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('warehouse.provinceOrState')"
          prop="province"
          label-width="120px"
        >
          <el-input
            v-model.trim="warehouseInfo.province"
            :placeholder="
              $t('system.pleaseInput') + $t('warehouse.provinceOrState')
            "
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('warehouse.city')"
          prop="city"
          label-width="120px"
        >
          <el-input
            v-model.trim="warehouseInfo.city"
            :placeholder="$t('system.pleaseInput') + $t('warehouse.city')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('warehouse.postCode')"
          prop="zipcode"
          label-width="120px"
        >
          <el-input
            v-model.trim="warehouseInfo.zipcode"
            :placeholder="$t('system.pleaseInput') + $t('warehouse.postCode')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('warehouse.contactName')"
          prop="contactPersion"
          label-width="120px"
        >
          <el-input
            v-model.trim="warehouseInfo.contactPersion"
            :placeholder="
              $t('system.pleaseInput') + $t('warehouse.contactName')
            "
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('warehouse.contactMobile')"
          prop="contactMobile"
          label-width="120px"
        >
          <el-input
            v-model.trim="warehouseInfo.contactMobile"
            :placeholder="
              $t('system.pleaseInput') + $t('warehouse.contactMobile')
            "
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('warehouse.contactMail')"
          prop="contactMail"
          label-width="120px"
        >
          <el-input
            v-model.trim="warehouseInfo.contactMail"
            :placeholder="
              $t('system.pleaseInput') + $t('warehouse.contactMail')
            "
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('warehouse.remark')"
          prop="remark"
          label-width="120px"
        >
          <el-input
            v-model.trim="warehouseInfo.remark"
            :placeholder="$t('system.pleaseInput') + $t('warehouse.remark')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button
          @click="handleClose"
          size="mini"
        >{{
          $t("system.cancel")
          }}</el-button>
        <el-button
          type="primary"
          @click="doCreateWarehouse"
          size="mini"
        >{{
          $t("system.confirm")
          }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import addressServer from "../source/address";
export default {
  props: ["isShowDialog"],
  emits: ["onDialogClose"],
  data() {
    return {
      warehouseInfo: {
        warehouseType: null,
        warehouseCode: null,
        companyName: null,
        consignee: null,
        consigneeAddress1: null,
        consigneeAddress2: null,
        consigneeAddress3: null,
        housenumber: null,
        country: null,
        province: null,
        city: null,
        zipcode: null,
        contactPersion: null,
        contactMobile: null,
        contactMail: null,
        remark: null,
      },
      warehouseRules: {
        warehouseType: [
          {
            required: true,
            message:
              this.$t("system.pleaseSelect") +
              this.$t("warehouse.warehouseType"),
            trigger: "change"
          }
        ],
        warehouseCode: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") +
              this.$t("warehouse.warehouseCode"),
            trigger: "blur"
          }
        ],
        companyName: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") + this.$t("warehouse.companyName"),
            trigger: "blur"
          }
        ],
        consigneeAddress1: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") +
              this.$t("warehouse.consigneeAddress1"),
            trigger: "blur"
          }
        ],
        country: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") + this.$t("warehouse.country"),
            trigger: "blur"
          }
        ],
        province: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") +
              this.$t("warehouse.provinceOrState"),
            trigger: "blur"
          }
        ],
        city: [
          {
            required: true,
            message: this.$t("system.pleaseInput") + this.$t("warehouse.city"),
            trigger: "blur"
          }
        ],
        zipcode: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") + this.$t("warehouse.postCode"),
            trigger: "blur"
          }
        ]
      },
      warehouseTypeList: [{ id: 1, name: "FBA" }, { id: 2, name: "海外仓" }]
    };
  },
  computed: {
    isShow() {
      return this.isShowDialog;
    }
  },
  methods: {
    handleClose() {
      this.$emit("onDialogClose");
    },
    doCreateWarehouse() {
      this.$refs["warehouseForm"].validate(valid => {
        if (valid) {
          addressServer.addWareHouse(this.warehouseInfo).then(() => {
            this.$confirm(
              this.$t("warehouse.createSuccess"),
              this.$t("system.continue") + "?",
              {
                confirmButtonText: this.$t("system.confirm"),
                cancelButtonText: this.$t("system.cancel"),
                type: "success"
              }
            )
              .then(() => {
                this.$refs["warehouseForm"].resetFields();
              })
              .catch(() => {
                this.handleClose();
              });
          });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :deep(.el-form-item__label) {
    font-size: 12px;
  }
  :deep(.el-input--mini) {
    max-width: 180px;
  }
}
</style>
