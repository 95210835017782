<template>
  <div class="container">
    <el-container>
      <el-header >
        <el-row
          type="flex"
          :gutter="10"
          style="width: 100vw"
        >
          <el-col :span="3">
            <el-input
              v-model="filterOption.warehouseCode"
              :placeholder="$t('warehouse.warehouseCode')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="filterOption.companyName"
              :placeholder="$t('warehouse.companyName')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="filterOption.consignee"
              :placeholder="$t('warehouse.consignee')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="filterOption.country"
              :placeholder="$t('warehouse.country')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="filterOption.provinceOrState"
              :placeholder="$t('warehouse.provinceOrState')"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-select
              filterable
              v-model="filterOption.warehouseTypeId"
              :placeholder="$t('warehouse.warehouseType')"
              clearable
              size="mini"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in warehouseTypeList"
                :key="index"
              ></el-option>
            </el-select>
          </el-col>
          <el-col
            :span="4"
            :offset="2"
          >
            <div style="float: right">
              <el-button
                type="primary"
                size="mini"
                @click="doSearch"
              >{{
                $t("system.search")
                }}</el-button>
              <el-button
                type="warning"
                size="mini"
                @click="doReset"
              >{{
                $t("system.reset")
                }}</el-button>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <div class="tableHeader">
          <!-- <el-button size="mini">{{ $t("system.import") }}</el-button> -->
          <el-button
            size="mini"
            @click="showCreateDialog"
          >{{
            $t("system.create")
            }}</el-button>
          <!-- <el-button size="mini">{{ $t("system.delete") }}</el-button> -->
        </div>
        <el-table
          :data="tableData"
          ref="warehouseTable"
          border
          :height="tHeight"
          v-loading="isTableLoading"
          @selection-change="onTableSelectionChange"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            width="40"
            fixed
          ></el-table-column>
          <el-table-column
            type="index"
            :label="$t('system.index')"
            width="60"
            align="center"
            fixed
          ></el-table-column>
          <el-table-column
            prop="warehouseType"
            :label="$t('warehouse.warehouseType')"
            align="center"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="warehouseCode"
            :label="$t('warehouse.warehouseCode')"
            align="center"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="companyName"
            :label="$t('warehouse.companyName')"
            align="center"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="consignee"
            :label="$t('warehouse.consignee')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="consigneeAddress1"
            :label="$t('warehouse.consigneeAddress1')"
            align="center"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="consigneeAddress2"
            :label="$t('warehouse.consigneeAddress2')"
            align="center"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="consigneeAddress3"
            :label="$t('warehouse.consigneeAddress3')"
            align="center"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="housenumber"
            :label="$t('warehouse.houseNumber')"
            align="center"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="country"
            :label="$t('warehouse.country')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="province"
            :label="$t('warehouse.provinceOrState')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="city"
            :label="$t('warehouse.city')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>

          <el-table-column
            prop="zipcode"
            :label="$t('warehouse.postCode')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="contactPersion"
            :label="$t('warehouse.contactName')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="contactMobile"
            :label="$t('warehouse.contactMobile')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="contactMail"
            :label="$t('warehouse.contactMail')"
            align="center"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="remark"
            :label="$t('warehouse.remark')"
            align="center"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('system.operation')"
            fixed="right"
            align="center"
            width="150"
          >
            <template v-slot="scope">
              <el-button
                type="text"
                size="mini"
                icon="el-icon-edit"
                @click="edit(scope.row)"
              >{{ $t("system.edit") }}</el-button>
              <!-- <el-button
                type="text"
                style="color:#E6A23C;"
                size="mini"
                icon="el-icon-delete"
                @click="del(scope.row)"
              >{{ $t("system.delete") }}</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div style="float: right; margin-top: 10px">
          <el-pagination
            @size-change="onTableSizeChange"
            @current-change="onTablePageChange"
            :current-page="filterOption.pageNo"
            :page-sizes="[10, 50, 100, 200]"
            layout="total,sizes,prev, pager, next"
            :total="filterOption.total"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>

    <create-warehouse-dialog
      v-if="isShowCreateDialog"
      :isShowDialog="isShowCreateDialog"
      @onDialogClose="closeCreateDialog"
    />
     <update-warehouse-dialog
      v-if="isShowUpdateDialog"
      :isShowDialog="isShowUpdateDialog"
      :rowInfo="currentRow"
      @onDialogClose="closeUpdateDialog"
    />
  </div>
</template>

<script>
import addressServer from "./source/address";
import mixin from "@/utils/mixin.js";
import CreateWarehouseDialog from "./dialogs/createWarehouse";
import UpdateWarehouseDialog from "./dialogs/updateWarehouse";
export default {
  name:"warehouse",
  components: {
    CreateWarehouseDialog,
    UpdateWarehouseDialog
  },
  mixins: [mixin],
  data() {
    return {
      isTableLoading: false,
      isShowCreateDialog: false,
       isShowUpdateDialog: false,
      filterOption: {
        warehouseTypeId: null,
        warehouseCode: null,
        companyName: null,
        consignee: null,
        country: null,
        provinceOrState: null,

        pageNo: 1,
        pageSize: 10,
        total: 0
      },

      warehouseTypeList: [{ id: 1, name: "FBA" }, { id: 2, name: "海外仓" }],

      tableData: [],
      tableSelection: [],
      currentRow: null
    };
  },
  computed: {
    tHeight() {
      return this.tableHeight - 135;
    }
  },
  mounted() {},
  methods: {
    doSearch() {
      addressServer
        .queryWareHouse({
          ...this.filterOption,
          current: this.filterOption.pageNo,
          size: this.filterOption.pageSize
        })
        .then(res => {
          this.tableData = res.data ? res.data.records : [];
          this.filterOption.total = res.data.total;
        });
    },
    edit(row) {
      this.currentRow = row;
      this.isShowUpdateDialog = true;
    },
    del(row) {
      console.log(row);
    },
    onTableSelectionChange(val) {
      this.tableSelection = val;
    },
    onTableSizeChange(size) {
      this.filterOption = {...this.filterOption,  pageSize: size,pageNo:1}
      this.doSearch()
     
    },
    onTablePageChange(page) {
      this.filterOption = {...this.filterOption,  pageNo: page,}
      this.doSearch()
    },
    showCreateDialog() {
      this.isShowCreateDialog = true;
    },
    closeCreateDialog() {
      this.isShowCreateDialog = false;
    },
    closeUpdateDialog() {
      this.isShowUpdateDialog = false;
    },
    doReset(){
       this.filterOption = {
        warehouseTypeId: null,
        warehouseCode: null,
        companyName: null,
        consignee: null,
        country: null,
        provinceOrState: null,
        pageNo: 1,
        pageSize: 10,
        total: 0
      };
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  height: calc(100% - 20px);
  background-color: white;
  overflow-y: scroll;
  padding: 10px;

  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
    :deep(el-form-item) {
      margin-right: 10px;
    }
  }

  :deep(.el-main) {
    padding: 0 20px;
    .tableHeader {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 48px;
    }
  }
}
</style>
