import request from "@/api/request";

const addressServer = {
    queryWareHouse: params =>
        request({
            url: "/baseWarehouse/queryWarehouse",
            method: "get",
            params: params,
        }),
    addWareHouse: params =>
        request.post('/baseWarehouse/addWarehouse', params),
    updateWareHouse: params =>
        request.post('/baseWarehouse/updateWarehouse', params),
}




export default addressServer